/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/prefer-default-export */
import * as React from 'react';
import firebase from 'firebase/app';

import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews';

import 'gatsby-plugin-prismic-previews/dist/styles.css';

import './src/styles/main.scss';

const firebaseConfig = {
  apiKey: 'AIzaSyDlz8VM2Hrhpgp0Di6rERgjjcFOyz78N9U',
  authDomain: 'enter-educators.firebaseapp.com',
  projectId: 'enter-educators',
  storageBucket: 'enter-educators.appspot.com',
  messagingSenderId: '892731891993',
  appId: '1:892731891993:web:d12b9be07ffae0dd54a588',
  measurementId: 'G-5MZQR1XX68',
};
// const firebaseConfig = {
//   apiKey: 'AIzaSyAgMSs9R9Z7t5CNxbLaooF9wmmP_sGIk4E',
//   authDomain: 'enter-prof.firebaseapp.com',
//   projectId: 'enter-prof',
//   storageBucket: 'enter-prof.appspot.com',
//   messagingSenderId: '570389601262',
//   appId: '1:570389601262:web:3398b58ad8717d3cde0cff',
//   measurementId: 'G-F9LQZ4CS40',
// }
const isBrowser = typeof window !== 'undefined';

if (isBrowser) {
  firebase.initializeApp(firebaseConfig);
}

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider>{element}</PrismicPreviewProvider>
);
