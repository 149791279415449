module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"enterprof","accessToken":"MC5ZWDU5QXhFQUFDb0FiMVpB.77-977-9dO-_vVpY77-977-9Q--_ve-_ve-_vTcZQe-_vULvv70kBlXvv73vv73vv707MO-_vWLvv70oRAY","promptForAccessToken":true,"apiEndpoint":"https://enterprof.cdn.prismic.io/api/v2","lang":"*","pageSize":100,"imageImgixParams":{"auto":"compress,format","fit":"max","q":50},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ENTER Educators","short_name":"ENTER Educators","description":"ENTER Educators | Professional Development. Be a Registered Professional Engineering Educator","start_url":"/","background_color":"#084298","theme_color":"#084298","display":"standalone","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"fa9767490c63a7f8dc8bb6972da0289d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-HE61MCKQNX"],"pluginConfig":{"head":false,"respectDNT":false,"exclude":["/preview/**"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
